// must be set before lazyload import
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = false;

import 'lazysizes';
import 'lazysizes-optimumx';
import { $$ } from 'query-selector';

export default () => {
  const saveData =
    'connection' in navigator && navigator.connection.saveData === true;

  // Lazyload images
  function initLazySizes() {
    // Show lower resolution images if saveData
    if (saveData) {
      const images = $$('.lazyload');

      for (const image of images) {
        image.dataset.optimumx = 1;
      }
    }

    // Remove bg to avoid artefacts
    document.addEventListener('lazyloaded', e => {
      e.target.parentNode.classList.add('o-figure__wrapper--transparent');
    });

    lazySizes.init();
  }

  initLazySizes();
};
