import animation from 'animation';
import product from 'product';
import { $ } from 'query-selector';

// no async/await so modules can load in parallel
export default () => {
  // immediately
  // animation(); // disabled b/c Firefox render artefacts
  product();

  // async
  if ($('.js-client-login')) import('client-login').then(m => m.default());
  if ($('.js-clients-slider')) import('clients-slider').then(m => m.default());
};
