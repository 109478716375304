import inView from 'in-view';
import { $, $$ } from 'query-selector';

export default () => {
  const products = $$('.js-product');
  if (!products) return;

  let count = 0;

  inView(
    products,
    (el, visible) => {
      if (visible) {
        el.classList.add('c-product--anim');
        if (count > 0) el.classList.add('c-product--anim-delay-' + count);

        count++;

        setTimeout(() => {
          count--;
        }, 100);
      }
    },
    {
      offset: 0
    }
  );
};
