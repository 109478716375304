import onResize from 'on-resize';
import transitionEnd from 'transition-end';
import vars from 'variables';
import { $ } from 'query-selector';

const langList = $('.js-lang-list');
const langToggle = $('.js-lang-toggle');

const langListActiveClass = 'o-lang__list--active';
const langListAnimClass = 'o-lang__list--anim';
let langVisible = false;

function showLang() {
  langVisible = true;

  langToggle.setAttribute('aria-expanded', 'true');

  langList.classList.add(langListActiveClass);
  langList.classList.add(langListAnimClass);
}

async function hideLang() {
  langVisible = false;

  langToggle.setAttribute('aria-expanded', 'false');

  langList.classList.remove(langListActiveClass);
  await transitionEnd(langList, 'opacity');
  langList.classList.remove(langListAnimClass);
}

export default () => {
  langToggle.addEventListener('click', e => {
    langVisible ? hideLang() : showLang();
  });

  // Close if link is clicked
  langList.addEventListener('click', e => {
    if (langVisible && e.target.tagName === 'A') hideLang();
  });

  // Close nav on resize
  onResize(
    () => {
      if (!langVisible || vars.browser.wide()) return;

      hideLang();
    },
    { horizontalOnly: true }
  );
};
