// Prevent Safari 10.1 nomodule bug
if (window.scriptLoaded) throw new Error('Safari 10.1 nomodule bug');
window.scriptLoaded = true;

// Set Webpack public path for async modules
const src = document.querySelector('script[data-path]').getAttribute('src');
__webpack_public_path__ = src.substr(0, src.lastIndexOf('/') + 1);

// Polyfills
import 'promise-polyfill'; // only in legacy build, also to make import() work
import 'classlist-polyfill'; // only in legacy build
import('focus-visible-polyfill'); // not immediately necessary

// Modules
import lazyload from 'lazyload';
import nav from 'nav';
import lang from 'lang';
import initPage from 'init-page';
import vars from 'variables';
import { $ } from 'query-selector';

function init() {
  lazyload();
  nav();
  lang();

  // Init page specific (async) modules, also used in page transition
  initPage();

  if (!vars.browser.legacy) {
    import('page-transition').then(module => module.default());
  }

  if ($('meta[name="analytics"]')) {
    import('analytics').then(module => module.default());
  }
}

init();
