import onResize from 'on-resize';
import transitionEnd from 'transition-end';
import vars from 'variables';
import { $ } from 'query-selector';

const navWrapper = $('.js-nav-wrapper');
const navToggle = $('.js-nav-toggle');
const navIcon = $('.js-nav-icon');
const navIconActiveClass = 'o-nav-icon--active';
const navWrapperActiveClass = 'c-nav__wrapper--active';
const navWrapperAnimClass = 'c-nav__wrapper--anim';
let navVisible = false;

function showNav() {
  navVisible = true;

  navToggle.setAttribute('aria-expanded', 'true');
  navIcon.classList.add(navIconActiveClass);

  navWrapper.classList.add(navWrapperActiveClass);
  navWrapper.classList.add(navWrapperAnimClass);
}

async function hideNav() {
  navVisible = false;

  navToggle.setAttribute('aria-expanded', 'false');
  navIcon.classList.remove(navIconActiveClass);

  navWrapper.classList.remove(navWrapperActiveClass);
  await transitionEnd(navWrapper, 'opacity');
  navWrapper.classList.remove(navWrapperAnimClass);
}

export default () => {
  navToggle.addEventListener('click', e => {
    navVisible ? hideNav() : showNav();
  });

  // Close nav if link is clicked
  navWrapper.addEventListener('click', e => {
    if (navVisible && e.target.tagName === 'A') hideNav();
  });

  // Close nav on resize
  onResize(
    () => {
      if (!navVisible || !vars.browser.wide()) return;

      hideNav();
    },
    { horizontalOnly: true }
  );
};
